import _ from "lodash"

export const Type = {
  FOCUS: "focus",
  INFO: "info",
  EXPERIENCE: "experience",
  TRANSFER: "transfer",
}

export const notionToAppMap = {
  "focus": Type.FOCUS,
  "information": Type.INFO,
  "experience": Type.EXPERIENCE,
  "transfer": Type.TRANSFER,
}

export const appToNotionMap = _.invert(notionToAppMap)