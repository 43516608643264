<template>
  <nav class="sidebar" :class="{'sidebar__state-open': isOpen}">
    <div class="container">
      <AnimatedIcon v-on:click="toggle"/>
      <div class="sidebar--side-trigger" v-on:click="toggle">
        <div class="sidebar--side-trigger-label">Menü</div>
      </div>
    </div>
  </nav>
</template>

<script>
import { computed, defineComponent, ref } from "vue"
import { storeToRefs } from "pinia"
import { useSessionStore } from "@/stores/session"
import AnimatedIcon from "@/components/AnimatedIcon"

export default defineComponent({
  components: { AnimatedIcon },
  setup() {
    const sessionStore = useSessionStore()
    const { activeProject } = storeToRefs(sessionStore)
    const projectName = computed(() => {
      if (!activeProject.value) return ""
      const { data: { name: projectName } } = activeProject.value
      return projectName
    })

    const isOpen = ref(false)

    const open = () => {
      isOpen.value = true
    }
    const close = () => {
      isOpen.value = false
    }
    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    return {
      activeProject,
      isOpen,
      projectName,
      close,
      open,
      toggle
    }
  },
})
</script>

<style lang="scss" scoped>
.sidebar {
  z-index: 9999;
  position: fixed;
  display: flex;
  width: 320px;
  height: 100vh;
  top: 0;
  right: calc(100% - 32px);
  transition: right .5s ease-out;
  background-color: var(--color-lightest-gray);

  &__state-open {
    right: calc(100% - 320px);
  }

  &--side-trigger {
    display: flex;
    flex-direction: column;
    position: absolute;
    cursor: pointer;
    width: 32px;
    top: 0;
    bottom: 0;
    right: 0;
    border-left: 3px solid var(--color-white);
    justify-content: center;

    &:hover {
      .sidebar--side-trigger-label {
        color: var(--color-gray);
      }
    }

    &-label {
      transform: rotate(-90deg);
    }
  }

  .container {
    position: relative;
  }

  .animated-icon {
    cursor: pointer;
    position: absolute;
    left: calc(100% + 16px);
    height: 80px;
    width: 60px;
  }
}
</style>
