import { createApp } from "vue"
import { createPinia } from "pinia"
import { INTERCOM_APP_ID, SUPER_DOMAIN } from "@/services/constants"
import NotionPlugin from "@/plugins/notion"
import IntercomPlugin from "@/plugins/intercom"
import iframeResizerDirective from "@/directives/iframeResizer"
import App from "@/App.vue"
import router from "@/router"
import { useDataProvider, useIntercomDataProvider } from "@/stores/session"
import "./style/main.scss"
import FontAwesomeIcon from "@/services/icons"

const pinia = createPinia()

const app = createApp(App)
  .use(pinia)
  .use(NotionPlugin, {
    dataProvider: useDataProvider(),
    superDomain: SUPER_DOMAIN
  })
  .use(IntercomPlugin, {
    appId: INTERCOM_APP_ID,
    dataProvider: useIntercomDataProvider(),
  })
  .use(router)
  .directive("ifresizer", iframeResizerDirective)
  .component("font-awesome-icon", FontAwesomeIcon)

app.mount("#app")
