import _ from "lodash"
import { ref, watch } from "vue"
import Intercom from "@intercomPlugin/lib/intercom"

const defaultLauncherSettings = {
  custom_launcher_selector: ".open-intercom",
  action_color: "#ff3366",
  background_color: "#3366ff",
  hide_default_launcher: true
}

export default class IntercomService {
  #intercom
  #dataProvider
  #launcherSettings
  isBooted = ref(false)

  constructor(appId, { dataProvider, launcherSettings = defaultLauncherSettings }) {
    this.#intercom = new Intercom({ appId })
    this.#dataProvider = dataProvider
    this.#launcherSettings = launcherSettings
  }

  startMessenger = (settingsUpdate = {}) => {
    if (!this.#intercom.ready) {
      this.#intercom.once("ready", () => this.rebootIntercom())
    } else {
      this.rebootIntercom()
    }
  }

  shutdown = () => {
    this.#intercom.shutdown()
    this.isBooted.value = this.#intercom.isBooted
  }

  rebootIntercom = () => {
    this.#intercom.shutdown()

    const user = this.#dataProvider.getIntercomUser()
    this.#intercom.boot({
      ...user,
      ...this.#launcherSettings
    })
    this.isBooted.value = this.#intercom.isBooted
  }

  update = (settingsUpdate) => {
    if(_.isEmpty(settingsUpdate)) return void 0
    this.#launcherSettings = { ...this.#launcherSettings, ...settingsUpdate }
  }
}
