import _ from "lodash"
import { notionToAppMap } from "@notionPlugin/model/FIETStep"
import NotionLinksService from "@notionPlugin/service/notionLinks"
import MiroBoardsService from "@notionPlugin/service/miroBoards"

export const defaultPathMap = [
  {
    pattern: /^\/?projects\/$/i,
    target: "/projects"
  },
  {
    pattern: /^\/learn\/(focus|information|experience|transfer)\/(.+)$/i,
    target: async (
      { path, matches },
      { deliverablesDataFetcher }
    ) => {
      const delData = await deliverablesDataFetcher()
      const stepType = notionToAppMap[matches[1]]
      const stepPathFragment = matches[2]
      const deliverable = getDeliverableByStep(delData, stepType, stepPathFragment)
      const { ["path-fragment"]: deliverablePathFragment } = deliverable ? deliverable.data : {}
      return `/deliverables/${deliverablePathFragment}/${stepType}`
    }
  },
  {
    pattern: /^\/learn\/deliverables\/(.+)$/i,
    target: ({ matches }) => {
      const deliverable = matches[1]
      return `/deliverables/${deliverable}/focus`
    }
  },
  //Match all remaining paths and return them as they are but but with the apps domain
  {
    pattern: /^\/(.+)$/i,
    target: ({ matches }) => {
      const path = matches[1]
      return `/${path}`
    }
  }
]

export const getDeliverableByStep = function (deliverables, stepType, stepPathFragment) {
  return _.find(deliverables, ({ data: delData }) => {
    return delData[stepType] && delData[stepType]["path-fragment"] === stepPathFragment
  })
}

export default {
  install: (app, {
    dataProvider,
    pathMap = defaultPathMap,
    superDomain }
  ) => {
    const { fetchDeliverables } = dataProvider
    const appliedOptions = { fetchDeliverables, pathMap }
    const notionLinkService = new NotionLinksService({
      dataProvider,
      superDomain
    })
    const miroBoardsService = new MiroBoardsService(dataProvider)

    app.config.globalProperties.mapNotionPath = notionLinkService.mapNotionPath
    app.config.globalProperties.mapNotionLink = notionLinkService.mapNotionLink
    app.provide("notionLinkService", notionLinkService)
    app.provide("miroBoardsService", miroBoardsService)
  }
}