/**
 * Custom Vue3 plugin for Intercom
 * a lot of this is inspired by https://github.com/mathieustan/vue-intercom/tree/master/src
 */
import { ref } from "vue"
import IntercomService from "./service/intercom"

let $intercomService

export default {
  install: (app, { appId, dataProvider }) => {
    $intercomService = new Promise((resolve, reject) => {
      try {
        const intercomService = new IntercomService(appId, {
          dataProvider
        })
        app.provide("intercomService", intercomService)
        resolve(intercomService)
      }
      catch (e) {
        reject(e)
      }
    })
  },
}

export const useIntercomService = async () => {
  return $intercomService ? await $intercomService : void 0
}
