<template>
  <div class="layout__app">
    <Sidebar></Sidebar>
    <main>
      <slot/>
    </main>
    <Footer/>
  </div>
</template>

<script>

import { defineComponent } from "vue"
import Footer from "@/components/Footer"
import Sidebar from "@/components/Sidebar"

export default defineComponent({
  components: { Sidebar, Footer },
  setup() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
  .view-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
      flex-grow: 1;
    }
  }
</style>
