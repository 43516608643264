<template>
  <footer class="footer">
    <div class="footer__column">
      <router-link to="/" class="footer__logo">
        <Logo src="/graphics/logo.png" />
      </router-link>
      <p class="footer__text">&copy; 2023 Seedbox Ventures GmbH</p>
    </div>
    <div class="footer__column">
      <h4 class="footer__column__title">Seedbox OS</h4>
      <nav class="footer__column__links">
        <router-link to="/projects" class="footer__column__links__link">
          Meine Projekte
        </router-link>
        <router-link to="/" class="footer__column__links__link">
          Dashboard
        </router-link>
        <!-- TODO: add correct support email -->
        <a
            href="mailto:support@seedbox-ventures.com"
            :class="{
              'footer__column__links__link': true,
              'open-intercom': isIntercomAvailable
            }">
          Hilfe erhalten
        </a>
      </nav>
    </div>
    <div class="footer__column">
      <h4 class="footer__column__title">Über uns</h4>
      <nav class="footer__column__links">
        <a href="https://seedbox-ventures.com/" target="_blank" class="footer__column__links__link">
          Seedbox Ventures
        </a>
        <a href="https://seedbox-ventures.com/impressum" target="_blank" class="footer__column__links__link">
          Impressum
        </a>
        <router-link to="/datenschutz" class="footer__column__links__link">
          Datenschutz
        </router-link>
        <router-link to="/agb" class="footer__column__links__link">
          AGB
        </router-link>
        <a href="https://linkedin.com/company/seedbox-ventures.com" target="_blank" class="footer__column__links__link">
          Follow us on LinkedIn!
        </a>
      </nav>
    </div>
  </footer>
</template>

<script>
import { defineComponent, inject, computed } from "vue"
import Logo from "@/components/Logo"

export default defineComponent({
  components: { Logo },
  setup() {
    const intercomService = inject("intercomService")
    const isIntercomAvailable = computed(() => (
      !intercomService
        ? false
        : intercomService.isBooted.value
    ))

    return {
      isIntercomAvailable
    }
  },
})
</script>

<style lang="scss" scoped>
@use "@/style/util/_responsiveness.scss" as res;

.footer {
  display: grid;
  background-color: var(--color-black);
  grid-template-columns: 3fr 2fr 2fr;
  padding: 3rem 1.5rem 6rem;

  @include res.mobile {
    grid-template-columns: 1fr;
    grid-row-gap: 4rem;
    padding: 4rem 2rem;
  }

  &__logo {
    display: block;
    margin-bottom: 2rem;
    max-width: 200px !important;
    max-height: 50px !important;
  }

  &__text {
    color: var(--color-gray);
    margin: 0;
  }

  &__column {
    @include res.mobile {
      &:first-child {
        order: 3;
      }
    }

    &__title {
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--color-light-gray);
      margin: 0 0 1rem;
    }

    &__links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &__link {
        font-size: 1.15rem;
        font-weight: 500;
        color: var(--color-light-gray);

        & + & {
          margin-top: .5em;

          @include res.mobile {
            margin-top: .75em;
          }
        }

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        &[target="_blank"] {
          &:hover,
          &:focus {
            &:after {
              content: ' ↗';
              font-size: .8em;
            }
          }
        }
      }
    }
  }
}
</style>