<template>
  <Layout>
    <NotionContainer :src="`${superUrl}?timestamp=${Date.now()}`"/>
  </Layout>
</template>

<script>
import { computed, defineComponent, inject } from "vue"
import { useSessionStore } from "@/stores/session"
import Layout from "@/layouts/AppLayout"
import NotionContainer from "@/plugins/notion/components/NotionContainer"
import { storeToRefs } from "pinia"

const handleRouteUpdate = async ({ params: { deliverableFragment,  stepType } }) => {
  const sessionStore = useSessionStore()
  await sessionStore.setActiveDeliverableAndStep(deliverableFragment, stepType)
}

export default defineComponent({
  components: { NotionContainer, Layout },
  setup () {
    const notionLinkService = inject("notionLinkService")
    const sessionStore = useSessionStore()
    const {
      activeDeliverable: deliverable,
      activeFIETStepType: fietStepType
    } = storeToRefs(sessionStore)

    const superUrl = computed(() => {
      if (!deliverable.value) return void 0
      return notionLinkService.getFIETStepLink(deliverable.value.data, fietStepType.value)
    })

    return {
      superUrl
    }
  },
  beforeRouteEnter: (handleRouteUpdate),
  beforeRouteUpdate: (handleRouteUpdate)
})

</script>

<style lang="scss" scoped>

</style>