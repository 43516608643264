<template>
  <div class="logo">
    <img class="logo__image--hover" :src="hoverSrc" v-if="hoverSrc">
    <img class="logo__image" :src="src">
  </div>
</template>

<script>
import { defineComponent, toRefs } from "vue"

export default defineComponent({
  props: {
    src: String,
    hoverSrc: String,
  },
  setup(props) {
    // const { src } = toRefs(props)

    return {}
  },
})

</script>

<style lang="scss" scoped>
  .logo {
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;

    &__image {
      z-index: 1;
      width: 100%;
    }

    &__image--hover {
      position: absolute;
      left: 0;
      width: 100%;
      //opacity: 0;
      transition: left .5s ease-out;
      svg {
        filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
      }
    }

    &:hover {
      .logo__image--hover {
        left: 1rem;
      }
    }
  }
</style>