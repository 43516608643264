import _ from "lodash"
import { computed, ref, watch } from "vue"
import { defineStore, storeToRefs } from "pinia"
import { collection, doc, getDocs, query, where } from "firebase/firestore"
import firebaseApp, { db } from "../services/firebase"
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth"
import Deliverable from "@/model/Deliverable"
import Project from "@/model/Project"
import Board from "@/model/Board"

export const useSessionStore = defineStore("session", () => {
  const fbAuth = getAuth(firebaseApp)
  const pInitialized = setPersistence(fbAuth, browserLocalPersistence)

  const isLoading = ref(false)
  const activeProject = ref(void 0)
  const activeProjectBoards = ref([])
  const activeDeliverable = ref({})
  const activeFIETStepType = ref("focus")
  const deliverables = ref([])
  const projects = ref([])
  const user = ref(fbAuth.user)
  const userDataObj = ref(void 0)
  const errors = ref([])

  watch(activeProject, (oldProject, newProject) => {
    if (newProject) {
      activeProjectBoards.value = [] //Reset active project boards, if project is resetted
    }
  })

  const deliverablesCollection = collection(db, "deliverables")
  const projectsCollection = collection(db, "projects")

  const isAuthenticated = async () => {
    await pInitialized
    return !!user.value
  }

  async function updateUser(newUser) {
    user.value = newUser
    userDataObj.value = newUser ? doc(db, "users", newUser.uid) : void 0
  }

  function loginWithEmailAndPassword(email, password) {
    isLoading.value = true

    return signInWithEmailAndPassword(fbAuth, email, password).then((userCredential) => {
      updateUser(userCredential.user)
      return userCredential
    })
      .catch(error => {
        switch(error.code) {
        case "auth/user-not-found":
        case "auth/wrong-password":
          throw new InvalidCredentialsError()
        default:
          throw new GeneralAuthError()
        }
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  function resetPasswordByEmail(email) {
    isLoading.value = true

    return sendPasswordResetEmail(fbAuth, email).then(() => {
      return true
    })
      .catch(error => {
        switch(error.code) {
        case "auth/user-not-found":
          throw new InvalidCredentialsError()
        default:
          throw new GeneralAuthError()
        }
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  async function fetchActiveProject() {
    // Return activeProject if it is defined
    if (activeProject.value) {
      return activeProject.value
    }

    // Return only project of user has only one
    const projects = await fetchProjects()
    //     if (projects && projects.length === 1) {
    activeProject.value = projects[0]
    return activeProject.value
    //     }

    // Return false, if activeProject is not defined and user has access to more than one projects
    //     return false
  }

  async function fetchProjects(forceRefresh = false) {
    if (!(projects.value && projects.value.length) || forceRefresh) {
      const projectsArray = []
      const q = query(projectsCollection, where("users", "array-contains",  userDataObj.value))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        projectsArray.push(new Project(doc))
      })
      projects.value = projectsArray
    }

    return projects.value
  }

  async function fetchDeliverables(forceRefresh = false) {
    if (!(deliverables.value && deliverables.value.length)   || forceRefresh) {
      const deliverablesArray = []
      const q = query(deliverablesCollection)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        deliverablesArray.push(new Deliverable(doc))
      })
      deliverables.value = deliverablesArray
    }

    return deliverables.value
  }

  async function purgeActiveDeliverable() {
    activeDeliverable.value = false
    activeFIETStepType.value = "focus"
  }

  async function setActiveDeliverableAndStep(deliverableFragment, stepType = "focus") {
    const deliverables = await fetchDeliverables()

    activeDeliverable.value = _.find(
      deliverables,
      ({
        data: { ["path-fragment"]: nextDeliverableFragment
        } }) => nextDeliverableFragment === deliverableFragment)

    activeFIETStepType.value = stepType
  }

  async function setActiveProjectById(projectId) {
    const projects = await fetchProjects()
    activeProject.value = _.find(projects, { id: projectId })
  }

  async function fetchActiveProjectBoards(forceRefresh = false) {
    if (!(activeProjectBoards.value && activeProjectBoards.value.length) || forceRefresh) {
      const activeProject = await fetchActiveProject()
      const boardsArray = []
      const boardsCollection = collection(db, "projects", activeProject.id, "boards")
      const q = query(boardsCollection)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        boardsArray.push(new Board(doc))
      })
      activeProjectBoards.value = boardsArray
    }

    return activeProjectBoards.value
  }

  onAuthStateChanged(fbAuth, updateUser)

  return {
    activeDeliverable,
    activeFIETStepType,
    activeProject,
    activeProjectBoards,
    errors,
    fetchActiveProject,
    fetchDeliverables,
    fetchProjects,
    fetchActiveProjectBoards,
    isAuthenticated,
    isLoading,
    loginWithEmailAndPassword,
    resetPasswordByEmail,
    purgeActiveDeliverable,
    setActiveDeliverableAndStep,
    setActiveProjectById,
    updateUser,
    user
  }
})

export function useDataProvider() {
  const sessionStore = useSessionStore()
  const {
    fetchActiveProjectBoards: fetchMiroBoards,
    fetchDeliverables,
    fetchActiveProject: fetchProject
  } = sessionStore

  return {
    fetchMiroBoards,
    fetchDeliverables,
    fetchProject
  }
}

export function useIntercomDataProvider() {

  const sessionStore = useSessionStore()
  const {
    user,
    activeProject: project
  } = storeToRefs(sessionStore)

  return {
    getIntercomUser: () => {
      return {
        user_id: user.value.uid,
        name: user.value.displayName,
        email: user.value.email,
        avatar: {
          type: "avatar",
          image_url: user.value.photoURL
        },
        active_project_id: project.value ? project.value.id : undefined
      }}
  }
}

export class GeneralAuthError extends Error {}
export class InvalidCredentialsError extends Error {}
