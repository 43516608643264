import { inject, watchSyncEffect } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { useIntercomService } from "@intercomPlugin"
import { useSessionStore } from "@/stores/session"
import Dashboard from "@/views/Dashboard.vue"
import DeliverableView from "@/views/DeliverableView.vue"
import ProjectList from "@/views/ProjectList.vue"
import Login from "@/views/Login.vue"
import ForgotPassword from "@/views/ForgotPassword.vue"
import StaticNotionView from "@/views/StaticNotionView.vue"

let sessionStore

const routes = [
  {
    path: "/",
    name: "Root",
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      const project = await sessionStore.fetchActiveProject()
      if (typeof project === "object") {
        const { id: projectId } = project
        next({ name: "Project", params: { projectId } })
      } else {
        next({ name: "Projects" })
      }
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: ProjectList,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/projects/:projectId",
    name: "Project",
    component: Dashboard,
    meta: {
      displayIntercom: true,
      requiresAuth: true
    },
  },
  {
    path: "/deliverables/:deliverableFragment/:stepType?",
    name: "Deliverable",
    component: DeliverableView,
    meta: {
      displayIntercom: true,
      requiresAuth: true
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/login/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword
  },
  {
    path: "/datenschutz",
    name: "Legal",
    component: StaticNotionView,
  },
  {
    path: "/agb",
    name: "AGB",
    component: StaticNotionView,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "Root",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Authentication Guard
router.beforeEach(async (to, from, next) => {
  const sessionStore = getSessionStore()
  const isAuthenticated = await sessionStore.isAuthenticated()
  const { name: toName } = to

  if (toName !== "Deliverable") {
    sessionStore.purgeActiveDeliverable()
  }

  if(!isAuthenticated && to.matched.some(record => record.meta.requiresAuth)) {
    next({ name: "Login" })
  } else if(isAuthenticated && to.name === "Login") {
    next({ name: "Root" })
  } else {
    next()
  }
})

router.beforeResolve(async to => {
  const intercomService = await useIntercomService()

  const { meta:{
    displayIntercom = false,
    requiresAuth = false } = {}
  } = to

  if (intercomService) {
    intercomService.update({ hide_default_launcher: !displayIntercom })
    requiresAuth
      ? intercomService.startMessenger()
      : intercomService.shutdown()
  }
})

function getSessionStore() {
  if (!sessionStore) {
    sessionStore = useSessionStore()
    sessionStore.$subscribe(onSessionStoreUpdate)
  }
  return sessionStore
}

async function onSessionStoreUpdate(mutation, state) {
  const activePath = router.currentRoute.value.path
  const isAuthenticated = !!state.user
  const { currentRoute: { value: { meta: { requiresAuth } } } } = router

  if (activePath === "/login" && isAuthenticated) {
    setTimeout(
      () => {
        router.push("/")
      }, 5
    )
  } else if (requiresAuth && !isAuthenticated) {
    router.push("/login")
  }
}

export default router
