<template>
  <div class="animated-icon">
    <img class="animated-icon__ellipse" :src="ellipseSrc">
    <img class="animated-icon__chevrons" :src="chevronsSrc">
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  setup() {
    const ellipseSrc = require("@assets/images/icon-part-ellipse.svg")
    const chevronsSrc = require("@assets/images/icon-part-chevrons.svg")
    return {
      ellipseSrc,
      chevronsSrc,
    }
  },
})

</script>

<style lang="scss" scoped>
.animated-icon {
  position: relative;
  padding: 1rem;
  box-sizing: border-box;

  &__ellipse {
    left: 0;
    z-index: 1;
  }

  &__chevrons {
    position: absolute;
    top: 1rem ;
    left: 1rem;
    transition: left .5s ease-out;
  }

  &:hover {
    .animated-icon__chevrons {
      left: 1.5rem;
    }
  }
}
</style>
