<template>
  <Layout>
    <NotionContainer
        :onLinkClick="onLinkClick"
        :src="`${superUrl}?timestamp=${Date.now()}`"/>
  </Layout>
</template>

<script>
import { computed, defineComponent, inject } from "vue"
import NotionContainer from "@/plugins/notion/components/NotionContainer"
import Layout from "@/layouts/AppLayout"
import router from "@/router"

export default defineComponent({
  components: { NotionContainer, Layout },
  setup() {
    const notionLinkService = inject("notionLinkService")

    const superUrl = computed(() => {
      if (!router.currentRoute.value) return void 0
      return notionLinkService.getStaticPageLink(router.currentRoute.value.fullPath.toLowerCase())
    })

    const onLinkClick = (link) => {
      if (link && link.indexOf("#cookie-settings") > -1) {
        // TODO: show cookie banner
        // window.ccc.showSettings()
        window.alert("SHOW COOKIE BANNER") // TEMP so proof functionality

        return false // false cancels the click handling in StaticPage
      }
      return void 0
    }

    return {
      onLinkClick,
      superUrl
    }
  },
})
</script>

<style lang="scss" scoped>

</style>