<template>
  <div class="iframe-container">
    <iframe
        v-ifresizer="iframeResizerOptions"
        class="iframe"
        width="100%"
        :src="src"
        :id="iFrameId"
        ref="iframe"
    >
    </iframe>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"

export default defineComponent({
  props: ["iFrameId", "onWdwPostMsg", "src"],
  setup(props) {
    const iframe = ref(null)
    const onWdwPostMsg = ref(props.onWdwPostMsg)
    const iframeResizerOptions = {
      bodyPadding: "0",
      onMessage: onWdwPostMsg?.value,
      log: false
    }

    function sendMessage(...args) {
      if (!iframe.value || !iframe.value.iFrameResizer) return void 0
      iframe.value.iFrameResizer.sendMessage(...args)
    }

    return {
      iframe,
      iframeResizerOptions,
      sendMessage
    }
  },
})
</script>

<style lang="scss" scoped>
.iframe-container {
  display: flex;
  position: relative;
}

.iframe {
  border: none;
  margin: 0;
  padding: 0;
}
</style>
