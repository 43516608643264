import _ from "lodash"

export default class MiroBoardService {
    #dataProvider

    constructor (dataProvider) {
      this.#dataProvider = dataProvider
    }

    getBoardsByIds = async (boardIds) => {
      const projectBoards = await this.#dataProvider.fetchMiroBoards()

      const mappedMiroBoardIds = _.chain(boardIds)
        .map((boardId) => {
          let boardUrl = projectBoards.find(board => board.data.placeholder === boardId)?.data.url

          return { boardId, boardUrl }
        })
        .without(void 0)
        .keyBy("boardId")
        .mapValues("boardUrl")
        .value()

      return mappedMiroBoardIds
    }

    getBoardPassword = async () => {
      const project = await this.#dataProvider.fetchProject()
      return project.data.boardPassword
    }
}
