/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core"

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

/* import specific icons */
import {
  faBolt,
  faFlask,
  faHeart,
  faRocket,
  faSnowflake,
  faUser,
  faUserSecret
} from "@fortawesome/free-solid-svg-icons"

library.add(faBolt)
library.add(faFlask)
library.add(faHeart)
library.add(faRocket)
library.add(faSnowflake)
library.add(faUser)
library.add(faUserSecret)

/* add icons to the library */
export default FontAwesomeIcon
export { library }