<template>
  <IframeContainer
      :src="src"
      :onWdwPostMsg="onWdwPostMsg"
      ref="iFrameContainer"
      iFrameId="notion-iframe"
  />
</template>
<script>

import IframeContainer from "@/components/IframeContainer"
import { defineComponent, inject, ref } from "vue"
import router from "@/router"

export default defineComponent({
  components: { IframeContainer },
  props: [ "src", "onLinkClick"],
  setup(props) {
    const onLinkClick = ref(props.onLinkClick)
    const notionLinkService = inject("notionLinkService")
    const miroBoardsService = inject("miroBoardsService")
    const iFrameContainer = ref(null)

    const handleLinkClickPostMsg = async ({ href: originalHref, target, payload = {} }) => {
      let url
      const sanitizedTarget = typeof target && target.length > 0 ? target : "_self"

      if (originalHref.startsWith("/")) {
        url = new URL(originalHref, `${window.location.protocol}${notionLinkService.superDomain}`)
      } else {
        url = new URL(originalHref)
      }
      const { hostname, pathname } = url

      if (typeof onLinkClick.value === "function") {
        // we can abort click handling here by return false in the CB function
        if (onLinkClick.value(originalHref, target, payload) === false) return void 0
      }

      if (hostname === notionLinkService.superDomain) {
        const targetPath = notionLinkService.mapNotionPath(pathname, payload)
        router.push(targetPath)
      } else if (hostname === window.location.hostname && sanitizedTarget === "_self") {
        router.push(pathname)
      } else {
        window.open(url.toString())
      }
    }

    const handleSubstitudeLinksPostMsg = async ({ links }) => {
      await notionLinkService.ready
      iFrameContainer.value.sendMessage({
        type: "sbos-substitute-links-response",
        payload: {
          mappedHrefs: await notionLinkService.mapNotionLinks(links),
        },
      })
    }

    const handleSubstituteMiroPostMsg = async ({ boardIds }) => {
      let responsePayload = {
        miroBoards: await miroBoardsService.getBoardsByIds(boardIds),
      }

      // send PW only if > 0 boards are sent
      if (responsePayload.miroBoards && Object.keys(responsePayload.miroBoards).length > 0) {
        responsePayload.miroMasterPassword = await miroBoardsService.getBoardPassword()
      }

      iFrameContainer.value.sendMessage({
        type: "sbos-substitute-miro-response",
        payload: responsePayload,
      })
    }

    const onWdwPostMsg = ({ message }) => {
      const { type: messageType, payload } = message

      switch (messageType) {
      case "sbos-link-click":
        return handleLinkClickPostMsg(message)
      case "sbos-substitute-links":
        return handleSubstitudeLinksPostMsg(payload)
      case "sbos-substitute-miro":
        return handleSubstituteMiroPostMsg(payload)
      default:
        // do nothing
      }
    }

    return {
      iFrameContainer,
      onWdwPostMsg,
    }
  }
})

</script>