<template>
  <div class="loading-container">
    <div class="loading-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  background-color: rgba(0, 0, 0, .8);
  justify-content: center;
  align-items: center;
}

.loading-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading-ripple 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading-ripple div:nth-child(2) {
  animation-delay: -0.6s;
}

@keyframes loading-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

</style>