import _ from "lodash"
import { appToNotionMap, notionToAppMap, Type as FIETStepType } from "@notionPlugin/model/FIETStep"

export const defaultPathMap = [
  {
    pattern: /^\/?projects\/$/i,
    target: "/projects"
  },
  {
    pattern: /^\/learn\/(focus|information|experience|transfer)\/(.+)$/i,
    target: (
      { path, matches },
      deliverables 
    ) => {
      const stepType = notionToAppMap[matches[1]]
      const stepPathFragment = matches[2]
      const deliverable = getDeliverableByStep(deliverables, stepType, stepPathFragment)
      const { ["path-fragment"]: deliverablePathFragment } = deliverable ? deliverable.data : {}
      return `/deliverables/${deliverablePathFragment}/${stepType}`
    }
  },
  {
    pattern: /^\/learn\/deliverables\/(.+)$/i,
    target: ({ matches }) => {
      const deliverable = matches[1]
      return `/deliverables/${deliverable}/focus`
    }
  },
  //Match all remaining paths and return them as they are but but with the apps domain
  {
    pattern: /^\/(.+)$/i,
    target: ({ matches }) => {
      const path = matches[1]
      return `/${path}`
    }
  }
]

export const getDeliverableByStep = function (deliverables, stepType, stepPathFragment) {
  return _.find(deliverables, ({ data: delData }) => {
    return delData[stepType] && delData[stepType]["path-fragment"] === stepPathFragment
  })
}

export default class NotionLinksService {
    #dataProvider
    #deliverables
    #pathMap
    superDomain
    ready

    constructor({ dataProvider, pathMap = defaultPathMap, superDomain }) {
      this.#dataProvider = dataProvider
      this.#pathMap = pathMap
      this.superDomain = superDomain
      this.ready = new Promise((resolve, reject) => {
        this.#dataProvider.fetchDeliverables()
          .then((deliverables) => {
            this.#deliverables = deliverables
            resolve(this)
          })
      })
    }

    getProjektDashboardLink(projectId) {
      const url = new URL(`https://${this.superDomain}`)
      url.pathname = `/projects/${projectId}`
      return url.toString()
    }

    getFIETStepLink(deliverable, fietStepType = FIETStepType.FOCUS) {
      const url = new URL(`https://${this.superDomain}`)
      const {
        [fietStepType]: { ["path-fragment"]: stepFragment },
      } = deliverable
      const stepTypeFragment = appToNotionMap[fietStepType]
      url.pathname = `/learn/${stepTypeFragment}/${stepFragment.toLowerCase()}`
      return url.toString()
    }

    getStaticPageLink(path) {
      const url = new URL(`https://${this.superDomain}`)
      url.pathname = path
      return url.toString()
    }

    mapNotionPath = (notionPath) => {
      for(let i = 0; i < this.#pathMap.length; i++) {
        const { pattern, target } = this.#pathMap[i]
        const matches = notionPath.match(pattern)
        if (matches) {
          if (typeof target === "string") {
            return target
          }
          if (typeof target === "function") {
            return target({ notionPath, matches }, this.#deliverables )
          }
        }
      }
      return notionPath
    }

    mapNotionLink = (originalLink) => {
      let originalUrl
      if (originalLink.startsWith("/")) {
        originalUrl = new URL(
          originalLink,
          `${window.location.protocol}${this.superDomain}`
        )
      } else {
        originalUrl = new URL(originalLink)
      }

      const {
        hostname: originalHostname,
        pathname: originalPathname
      } = originalUrl

      if (originalHostname !== this.superDomain) {
        return void 0
      }

      const targetUrl = new URL(
        this.mapNotionPath(originalPathname),
        `${window.location.protocol}${window.location.host}`,
      )

      return {
        original: originalLink,
        substitute: targetUrl.toString()
      }
    }

    mapNotionLinks = (originalLinks) => {
      return _.chain(originalLinks)
        .map(this.mapNotionLink)
        .without(void 0)
        .keyBy("original")
        .mapValues("substitute")
        .value()
    }
}