<template>
  <Layout>
    <NotionContainer :src="`${superUrl}?timestamp=${Date.now()}`"/>
  </Layout>
</template>

<script>
import { computed, defineComponent, inject, onMounted } from "vue"
import { useSessionStore } from "@/stores/session"
import Layout from "@/layouts/AppLayout"
import NotionContainer from "@/plugins/notion/components/NotionContainer"
import { storeToRefs } from "pinia"

const handleRouteUpdate = async ({ params: { projectId } }, from) => {
  const sessionStore = useSessionStore()
  await sessionStore.setActiveProjectById(projectId)
}

export default defineComponent({
  components: { NotionContainer, Layout },
  setup () {
    const notionLinkService = inject("notionLinkService")
    const intercomService = inject("intercomService")
    const sessionStore = useSessionStore()
    const {
      activeProject: project
    } = storeToRefs(sessionStore)

    const superUrl = computed(() => {
      if (!project.value) return void 0
      return notionLinkService.getProjektDashboardLink(project.value.id.toLowerCase())
    })

    onMounted(() => {
      intercomService.startMessenger()
    })

    return {
      superUrl
    }
  },
  beforeRouteEnter: (handleRouteUpdate),
  beforeRouteUpdate: (handleRouteUpdate)
})

</script>

<style lang="scss" scoped>

</style>